import React from 'react';
import CheckEmptyIcon from "./CheckEmptyIcon.jsx";
import DeleteIcon from "./DeleteIcon";

function TodoItem(props) {
  
  const {todo: {id, title, isCompleted}, toggleTodo, delTodo} = props;
  
  return (
      <div className="flex mb-3">
        <div
          className="flex items-center mr-1 h-16"
          onClick={() => toggleTodo(id)}
        >
          <CheckEmptyIcon isCompleted={isCompleted}/>
        </div>
        <div className={isCompleted ? 'line-through  text-zinc-300 flex items-center bg-zinc-800 p-5 w-full mr-1 h-16' : ' text-zinc-300 flex items-center bg-zinc-800 p-5 w-full mr-1 h-16'}>{title}</div>
        <DeleteIcon
          isCompleted={isCompleted}
          id={id} delTodo={delTodo}
        />
      </div>
  );
}

export default TodoItem;
