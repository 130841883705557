import React from 'react';
import {MdOutlineAddCircle} from 'react-icons/md';

function AddTodoItem(props) {
  
  const { addTodo, todoInputHandler, isInputValue, inputTodo} = props;
  
  return (
    <div className="flex justify-start text-zinc-400 align-middle border-2 border-zinc-800 rounded-2xl h-10 mb-8">
      <button
        onClick={() => addTodo()}
        className="self-center disabled:opacity-20"
        disabled={!isInputValue}
      >
        <MdOutlineAddCircle className="text-pink-500 mx-2 hover:text-pink-800 transition-colors ease-in-out duration-300 cursor-pointer" size={24}/>
      </button>
      <div className="bg-zinc-900 w-full">
        <input
          onChange={(e) => todoInputHandler(e)}
          onKeyUp={(e) => e.key === 'Enter' && addTodo()}
          type="text"
          placeholder="Добавить задание"
          className="bg-zinc-900 h-full w-full"
          value={inputTodo}
        />
      </div>
    </div>
  );
}

export default AddTodoItem;
