import React, {useState, useEffect} from 'react';
import TodoItem from "../components/TodoItem";
import AddTodoItem from "../components/AddTodoItem";


function Home() {
  
	const initTodos = JSON.parse(localStorage.getItem('todos')) || [];
  
  
  const [todos, setTodos] = useState(initTodos);
  let [inputTodo, setInputTodo] = useState('');
  const [isInputValue, setIsInputValue] = useState(false)
  
  function toggleTodo(id) {
    let newTodos = [...todos];
    let t = newTodos.find(todo => todo.id === id)
    t.isCompleted = !t.isCompleted;
    setTodos(newTodos);
  }
  
  function delTodo(id) {
    let newTodos = [...todos];
    let arr = newTodos.filter(todo => todo.id !== id);
    setTodos(arr);
  }
  
  function addTodo() {
    if(!inputTodo) {
      return;
    }
      setTodos([{
        id: Math.random(),
        title: inputTodo,
        isCompleted: false
      }, ...todos])
      setInputTodo('');
      setIsInputValue(false);
  }
  
  function todoInputHandler(e) {
    e.preventDefault();
    if(!e.target.value) {
      setIsInputValue(false);
      setInputTodo('');
    } else {
      setIsInputValue(true);
      setInputTodo(e.target.value);
    }
  }
  
  useEffect(() => {
    localStorage.setItem('todos', JSON.stringify(todos))
  }, [todos])
  
  
  return (
    <div className="w-2/3 mx-auto ">
      <h1 className="text-xl font-bold text-center mb-8">Todos List</h1>
      <AddTodoItem
        addTodo={addTodo}
        todoInputHandler={todoInputHandler}
        isInputValue={isInputValue}
        inputTodo={inputTodo}
      />
      {
        todos.map((todo) =>
          <TodoItem
            todo={todo}
            key={todo.id}
            delTodo={delTodo}
            toggleTodo={toggleTodo}
          />
        )
      }
    </div>
  );
}

export default Home;
