import React from 'react';
import {BsTrash} from 'react-icons/bs';

function DeleteIcon(props) {
  
  const {delTodo, id, isCompleted} = props;
  return (
    <>
      <button
        className="bg-zinc-800 rounded-r-2xl px-1 py-6 h-16 disabled:opacity-20"
        disabled={!isCompleted}
        onClick={() => delTodo(id)}
      >
        <BsTrash
          className="text-pink-400 hover:text-pink-800 transition-colors ease-in-out duration-300"
          size={20}
        />
      </button>
    </>
  );
}

export default DeleteIcon;
